<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="3">
        <b-card>
          <b-form-group
            id="input-group-name"
            label="Он:"
            label-for="input-name"
          >
            <b-select :options="years" v-model="form.year"></b-select>
          </b-form-group>
          <b-form-group
            id="input-group-name"
            label="Сар:"
            label-for="input-name"
          >
            <b-select :options="months" v-model="form.month"></b-select>
          </b-form-group>
          <b-button variant="primary" @click="loadData()">Шүүх</b-button>
        </b-card>
      </b-col>
      <b-col sm="9">
        <b-card>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <table class="table table-sm table-bordered">
            <tbody>
              <tr>
                <td><strong>Шимтгэлийн орлого</strong></td>
                <td class="text-right">
                  {{ loan_income["INCODE_LOAN_FEE"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Хугацаандаа байгаа зээлийн хүүгийн орлого</strong>
                </td>
                <td class="text-right">
                  {{ loan_income["INCODE_LOAN_INTEREST"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Хугацаа хэтэрсэн зээлийн хүүгийн орлого</strong>
                </td>
                <td class="text-right">
                  {{ loan_income["INCODE_LOAN_INTEREST_OVERDUE"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>Жигнэсэн дундаж хүү</strong></td>
                <td class="text-right">
                  {{ loan_income["INCODE_LOAN_INTEREST_WEIGHTED"] | currecry }}%
                </td>
              </tr>
            </tbody>
          </table>
          <h3>Багцийн үлдэгдэл /хугацаа хэтрэлтээр/</h3>
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center">Хувийн зээлийн шугам</th>
                    <th class="text-center">Худалдан авалтын зээлийн шугам</th>
                    <th class="text-center">Уламжлалт /гар утас/</th>
                    <th class="text-center">Уламжлалт /автомашин/</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="key" v-for="(name, key) in overdue_list">
                    <td>
                      <strong>{{ name }}</strong>
                    </td>
                    <td class="text-right">
                      {{ loan_balance["PL"][key] | currecry }}₮
                    </td>
                    <td class="text-right">
                      {{ loan_balance["PLC"][key] | currecry }}₮
                    </td>
                    <td class="text-right">
                      {{ loan_balance["TRAD_PHONE"][key] | currecry }}₮
                    </td>
                    <td class="text-right">
                      {{ loan_balance["TRAD_MACHINE"][key] | currecry }}₮
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h3>Зээл олголт</h3>
          <table class="table table-sm table-bordered">
            <tbody>
              <tr>
                <td><strong>Хувийн зээлийн шугам</strong></td>
                <td class="text-right">
                  {{ loan_take["LOAN_TAKE_PL"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Худалдан авалтын зээлийн шугам</strong>
                </td>
                <td class="text-right">
                  {{ loan_take["LOAN_TAKE_PLC"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Уламжлалт /гар утас/</strong>
                </td>
                <td class="text-right">
                  {{ loan_take["LOAN_TAKE_TRAD_PHONE"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>Уламжлалт /автомашин/</strong></td>
                <td class="text-right">
                  {{ loan_take["LOAN_TAKE_TRAD_MACHINE"] | currecry }}₮
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Зээл төлөлтийн дүн</h3>
          <table class="table table-sm table-bordered">
            <tbody>
              <tr>
                <td><strong>Хувийн зээлийн шугам</strong></td>
                <td class="text-right">
                  {{ loan_repayment["LOAN_PAYMENT_PL"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Худалдан авалтын зээлийн шугам</strong>
                </td>
                <td class="text-right">
                  {{ loan_repayment["LOAN_PAYMENT_PLC"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Уламжлалт /гар утас/</strong>
                </td>
                <td class="text-right">
                  {{ loan_repayment["LOAN_PAYMENT_TRAD_PHONE"] | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>Уламжлалт /автомашин/</strong></td>
                <td class="text-right">
                  {{ loan_repayment["LOAN_PAYMENT_TRAD_MACHINE"] | currecry }}₮
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Report.list.month",
  components: {
    axios,
  },
  data: function() {
    return {
      isLoading: false,
      form: {
        year: moment().year(),
        month: moment().month() + 1,
      },
      columns: [
        { label: "", key: "name" },
        { label: "Дүн", key: "amount", class: "text-right" },
        { label: "Тоо", key: "count", class: "text-right" },
      ],
      names: [
        "Зээлийн эхний үлдэгдэл",
        "Шинээр олгосон зээл",
        "Төлөгдсөн дээл",
        "Зээлийн эцсийн үлдэгдэл",
        "Хэвийн",
        "Хугацаа хэтэрсэн",
      ],
      items: [],
      loan_balance: {
        PL: {},
        PLC: {},
        TRAD_PHONE: {},
        TRAD_MACHINE: {},
      },
      loan_take: {},
      loan_repayment: {},
      loan_income: {},
      overdue_list: {
        LOAN_BALANCE_NORMAL: "Хэвийн зээл",
        LOAN_BALANCE_OVERDUE_15: "15 хүртэл хоног",
        LOAN_BALANCE_OVERDUE_30: "16-30 хоног",
        LOAN_BALANCE_OVERDUE_60: "31-60 хоног",
        LOAN_BALANCE_OVERDUE_90: "61-90 хоног",
        LOAN_BALANCE_OVERDUE_180: "91-180 хоног",
        LOAN_BALANCE_OVERDUE_365: "181-365 хоног",
        LOAN_BALANCE_OVERDUE_365_PLUS: "365+",
      },
    };
  },
  computed: {
    years: function() {
      var result = [];
      for (var i = 2018; i <= moment().year(); i++) result.push(i);
      return result;
    },
    months: function() {
      var result = [];
      for (var i = 1; i <= 12; i++) result.push(i);
      return result;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    generateData: function(_items) {
      this.$data.loan_balance = {
        PL: {},
        PLC: {},
        TRAD_PHONE: {},
        TRAD_MACHINE: {},
      };
      this.$data.loan_take = {};
      this.$data.loan_repayment = {};
      this.$data.loan_income = {};
      for (var i in _items) {
        let item = _items[i];
        //console.log(item["code"]);
        if (item["code"].includes("LOAN_BALANCE")) {
          this.$data.loan_balance[item["category"]][item["code"]] =
            item["value"];
        } else if (item["code"].includes("LOAN_TAKE")) {
          this.$data.loan_take[item["code"]] = item["value"];
        } else if (item["code"].includes("LOAN_PAYMENT")) {
          this.$data.loan_repayment[item["code"]] = item["value"];
        } else {
          this.$data.loan_income[item["code"]] = item["value"];
        }
      }
    },
    loadData: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "ReportWebService/monthly_report",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              year: this.$data.form.year,
              month: this.$data.form.month,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response);
            this.isLoading = false;
            if (response.body.status == 500) {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            } else if (response.body.status != 1) {
              this.generateData(response.body.data);
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
  },
};
</script>
